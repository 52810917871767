.date-picker-wrapper {
    margin-bottom: 10px;
  }
  
  .date-picker-input {
    display: block;
    width: 100%;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid #e9e9f1;
    border-radius: 6px;
    outline: none;
    transition: border-color 150ms ease-in-out;
  }
  
  .date-picker-input:focus {
    border-color: #3f51b5;
  }
  